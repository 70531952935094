<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 bookings-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: this.backLinkName
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("bookings.bookings.slots") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <div class="clebex-item-section">
      <div class="clebex-item-section-item overflow-hidden">
        <space-calendar
          :select-date-fn="selectDate"
          :select-month-fn="selectMonth"
          :disable-previous-days="true"
          :selectedDate="selectedDate"
        ></space-calendar>
      </div>
    </div>
    <div class="clebex-item-section" v-if="slots">
      <select-slots :slots="slots"></select-slots>
    </div>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapGetters, mapState, mapActions } from "vuex";
import { DateTime } from "luxon";
import SpaceCalendar from "@/components/plans/SpaceCalendar";
import SelectSlots from "@/components/plans/SelectSlots";

export default {
  name: "BookingsDeclarationCreate",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    SpaceCalendar,
    SelectSlots
  },
  data() {
    return {
      selectedDate: null,
      selectedMonth: null
    };
  },
  created() {
    this.setSelectedSlot(null);
    this.selectDate(DateTime.fromISO(DateTime.now()));
  },
  computed: {
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
    ...mapState("plan", ["slots", "selectedSlot"])
  },
  methods: {
    ...mapActions("plan", ["getSlots", "setSelectedSlot"]),
    selectDate(date) {
      this.selectedDate = date.toFormat("yyyy-MM-dd");
      this.getSlots({
        payload: {
          types: ["DAY", "HALF_DAY", "HOUR", "HALF_HOUR", "QUARTER_HOUR"],
          type: "default",
          date: this.selectedDate,
          timezone: DateTime.now().zoneName
        },
        noLoaders: false
      });
    },
    selectMonth(month, year) {
      this.selectedMonth = `${year}-${month < 10 ? "0" : ""}${month}`;
    }
  },
  props: {
    backLinkName: {
      type: String
    }
  }
};
</script>
